<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <!-- <el-button class="mr10" type="primary">添加分类</el-button> -->
        <el-input class="w220 mr10" v-model="keyWord" placeholder="文章标题" />
        <el-date-picker
          v-model="pickerValue"
          class="mr10"
          align="right"
          value-format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button type="primary" @click="$router.push('/isDrafts')">草稿箱</el-button>
        <el-button class="ml10" type="primary" @click="centerDialogVisible = true" :disabled="!hasPermi"
          >发布文章</el-button
        >
      </div>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="title" align="center" show-overflow-tooltip label="文章标题" />
        <el-table-column prop="originAuthor" align="center" label="来源">
          <template slot-scope="scope">
            {{ scope.row.originAuthor ? scope.row.originAuthor : "——" }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间">
          <template slot-scope="scope">
            {{ setDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="shareNum" align="center" label="分享次数" />
        <el-table-column prop="readNum" align="center" label="阅读次数" />
        <el-table-column prop="readTime" align="center" label="阅读时长" />
        <el-table-column prop="nickeName" align="center" label="创建者" />
        <el-table-column prop="isDrafts" align="center" label="发布状态">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.isDrafts == 1 ? false : true"
              @change="(e) => switchChange(e, scope.row.dynamicId)"
              active-text="发布"
              inactive-text="关闭"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="edit(scope.row)" :disabled="!hasPermi">编辑</el-button>
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm='del(scope.row.dynamicId)'
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除这篇文章吗？">
              <el-button slot="reference" style="color: #fd634e" type="text" :disabled="!hasPermi">删除</el-button>
            </customPopconfirm>
            
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="发布文章"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      width="50%"
      center
    >
      <postArticles @submitForm="submitForm" ref="postArticles" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import postArticles from "./components/postArticles";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  getDynamicInfo,
  updateDynamicState,
  addDynamicInfo,
  delDynamicInfo
} from "@/api/articleManagement";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
    postArticles,
    customPopconfirm
  },
  data() {
    return {
      hasPermi: true,
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "", //文章标题
      pickerValue: "", //选择时间
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableData: [],
    };
  },
  created() {
    this.getDynamicInfoAsync();
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    /**@method 获取文章管理 */
    async getDynamicInfoAsync(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        isDrafts:0,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await getDynamicInfo(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDynamicInfoAsync();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDynamicInfoAsync();
    },
    /**@method 切换文章发布状态
     * @param {Boolean} e - switch值
     * @param {String} id - id
     */
    async switchChange(e, id) {
      let data = {
        dynamicId: id,
        isDrafts: e ? 0 : 1,
      };
      await updateDynamicState(data);
      await this.getDynamicInfoAsync();
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        startTime: this.pickerValue ? `${this.pickerValue[0]} 00:00:00` : "",
        endTime: this.pickerValue ? `${this.pickerValue[1]} 23:59:59` : "",
        title: this.keyWord,
      };
      this.getDynamicInfoAsync(data);
    },
    determine(data) {
      this.$refs.postArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        isDrafts: val.isDrafts ? 0 : 1,
        type:1,
        ...val,
      };
      try {
        await addDynamicInfo(data, { showLoading: true });
        this.centerDialogVisible = false;
        await this.getDynamicInfoAsync();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.centerDialogVisible = true;
      const {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        createUserId,
        createTime,
        isDel,
        isDrafts,
      } = val;
      let data = {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        createUserId,
        createTime,
        isDel,
        isDrafts:isDrafts ? true : false,
      }
     this.$nextTick(() => {
        this.$refs.postArticles.ruleForm = data;
        this.$refs.postArticles.fileList.push({name:'',url:photos})
     })
    },
    /**@method 删除 
     * @param {String} val - 文章id
    */
    async del(val){
      try {
        await delDynamicInfo({dynamicId:val});
        await this.getDynamicInfoAsync();
      } catch (error) {
        console.log(error)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>